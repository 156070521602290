import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SubscribeForm from "../components/subscribe-form"
import styled from "@emotion/styled"

const Container = styled.div`
  text-align: center;
`

const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 100vh;
`

const NameHeader = styled.h1`
  font-size: 3.5rem;
  margin-bottom: 1rem;
`

const SubscribePage = () => (
  <Layout>
    <SEO title="Subscribe to my Newsletter" />
    <Container>
          <NameHeader id="NameHeader">Jeroen Van Hautte</NameHeader>
    </Container>
    <p>I write about the things that fill my days, as well as those that keep me up at night. For the most part, that results in a mix of thoughts on computer science, artificial intelligence and HR, but sometimes I might show up with something else entirely.</p>
    <p>If you'd like to stay up to date with the things that are keeping me busy, enter your details below!</p>
    <SubscribeForm source={"Subscribe Page"} />
  </Layout>
)

export default SubscribePage
